import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Grid, createStyles, makeStyles } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Master from '../components/Master'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

const Contato = () => {

    const data = useStaticQuery(graphql`
        query {
            page(id: {eq: "5e97534ea53d761b6e34ff14"}) {
                title
                description
                keywords
                details
            }
        }
    `)
    const page = data.page

    const useStyles = makeStyles(theme =>
        createStyles({
            root: {
                display: 'flex',
                flexWrap: 'wrap',
            },
            width: {
                width: '100%'
            },
        }),
    )

    const classes = useStyles()

    const imageMediaQuery = useMediaQuery('(min-width:380px)');

    return (
        <Master title={page.title}>
            <GatsbySeo
                title={page.title}
                description={page.description}
                canonical={`${process.env.SITE_URL}/contato`}
                noindex={true}
                nofollow={true}
                openGraph={{
                    url: `${process.env.SITE_URL}/contato`,
                    title: page.title,
                    description: page.description,
                    images: [
                        {
                            url: `${process.env.SITE_IMAGE_OG_512}`,
                            width: 512,
                            height: 512,
                            alt: page.title
                        }
                    ],
                    site_name: `${process.env.SITE_NAME}`,
                }}
                twitter={{
                    handle: '@handle',
                    site: '@site',
                    cardType: 'summary_large_image',
                }}
            />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <h1>{page.title}</h1>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    {imageMediaQuery ?
                        <img src={`${process.env.SITE_IMAGE}`} title={page.title} alt={page.title} /> :
                        <img className={classes.width} src={`${process.env.SITE_IMAGE}`} title={page.title} alt={page.title} />}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div dangerouslySetInnerHTML={{ __html: page.details }} />
                </Grid>
            </Grid>
        </Master>
    )
}

export default Contato